// Support a progress bar label with the class 'progress-bar-label-centered', which centers the label text on the full
// progress bar width. Dark text must be used to ensure the label is visible on any background color.
// This class should also be used in conjunction with [data-bs-theme="light"] to make the text readable
.progress:has(.progress-bar-label-centered) {
  position: relative;

  .progress-bar-label-centered {
    position: absolute;
    width: 100%;
    text-align: center;
    color: var(--bs-dark);
  }
}
