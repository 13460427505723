svg#senti-logo {
  height: 1em;
  width: auto;
  vertical-align: -0.125em;

  .st1 {
    fill: none;
    stroke: #2b2b2b;
    stroke-width: 10.9608;
    stroke-miterlimit: 10;

    // Use a white stroke when the theme is dark
    [data-bs-theme="dark"] & {
      stroke: white;
    }
  }

  .st2 {
    fill: none;
    stroke: #fe3b1f;
    stroke-width: 11.188;
    stroke-miterlimit: 10;
  }
}
