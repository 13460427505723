/* stylelint-disable no-descending-specificity */

@import "npm:bootstrap/scss/functions";
@import "npm:bootstrap/scss/variables";
@import "npm:bootstrap/scss/mixins";

.table {
  // Always add a border below the table header
  // NOTE: We cannot use Bootstrap's .table-group-divider class applied to the table body, because that will not stick with the header
  thead tr:last-child th {
    border-bottom: calc(var(--bs-border-width) * 2) solid var(--bs-table-color);
  }
}

// Support responsive tables with sticky headers
// In this case we need to use separate borders and adjust the Bootstrap styling accordingly. Rendering table borders
// while the header is stuck does not work. See: https://stackoverflow.com/questions/50361698/border-style-do-not-work-with-sticky-position-element
.sticky-table-container {
  > table {
    border-collapse: separate;
    border-spacing: 0;

    > thead {
      position: sticky;
      top: 0;
    }

    > thead th,
    > tbody td {
      border-bottom: var(--bs-border-width) solid var(--bs-table-border-color);
      border-left: none;
    }

    // Apply a top border to the first row of cells
    // NOTE: It would be easier to just apply this to the table, but we need the border to stick when scrolling
    > thead:first-child > tr:first-child > th {
      border-top: var(--bs-border-width) solid var(--bs-table-border-color);
    }

    // Apply a left border to the first column of cells
    // NOTE: It would be easier to just apply this to the table, but we'll do it this way for consistency
    > thead > tr > th:first-child,
    > tbody > tr > td:first-child {
      border-left: var(--bs-border-width) solid var(--bs-table-border-color);
    }
  }
}

// Support adding manual striping to rows rather than the default automatic striping provided by bootstrap
tr.table-row-striped {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

// Add support for double borders. These are useful for splitting up the table into sections
.border-start-double {
  border-left: calc(max(var(--bs-border-width), 4px)) double var(--bs-table-border-color) !important;
}

.border-end-double {
  border-right: calc(max(var(--bs-border-width), 4px)) double var(--bs-table-border-color) !important;
}

.border-top-double {
  border-top: calc(max(var(--bs-border-width), 4px)) double var(--bs-table-border-color) !important;
}

.border-bottom-double {
  border-bottom: calc(max(var(--bs-border-width), 4px)) double var(--bs-table-border-color) !important;
}

// A table containing hierarchical data, an ascii tree column and shaded row backgrounds to indicate depth
.table-hierarchical {
  td {
    // Eliminate vertical padding for cells so that the ascii tree is easier to read
    padding-top: 0;
    padding-bottom: 0;
  }

  // Default styling for depths above 10
  tr[class*="tree-depth-"] > td {
    background-color: shade-color($white, 100%);
    color: var(--bs-light);
  }

  // Shade each depth level a little darker than the last
  @for $i from 0 through 10 {
    tr.tree-depth-#{$i} > td {
      background-color: shade-color($white, $i * 10%);
      color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));

      // Switch to light text at depth 5
      @if $i >= 5 {
        color: var(--bs-light);
      }
    }
  }
}

@include color-mode(dark) {
  .table-hierarchical {
    // Default styling for depths above 10
    tr[class*="tree-depth-"] > td {
      background-color: tint-color($gray-900, 100%);
      color: var(--bs-dark);
    }

    // Shade each depth level a little lighter than the last
    @for $i from 0 through 10 {
      tr.tree-depth-#{$i} > td {
        background-color: tint-color($gray-900, $i * 10%);
        color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));

        // Switch to dark text at depth 5
        @if $i >= 5 {
          color: var(--bs-dark);
        }
      }
    }
  }
}
